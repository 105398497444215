 /* Style the navbar */
 .App
 {
  height: 100vh;
  display: flex;
  flex-direction: column;

 }
 main{
  display:flex ;
  align-items: center;
  flex: 1;
  flex-direction: column;

 }
.input-file {
	position: relative;
	display: inline-block;
}
.input-file-text {
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
	padding: 0 10px;
	line-height: 40px;
	text-align: left;
	height: 40px;
	display: block;
	float: right;
	box-sizing: border-box;
	width: 600px;
	/*border-radius: 6px 0px 0 6px;*/
	border: 1px solid #ddd;
}
.input-file-btn {
position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 14px;
	vertical-align: middle;
	color: rgb(255 255 255);
	text-align: center;
	/*border-radius: 4px 0px 0px 4px;*/
	background-color: #419152;
	line-height: 22px;
	height: 40px;
	padding: 10px 20px;
	box-sizing: border-box;
	border: none;
	margin: 0;
	transition: background-color 0.2s;
}
.br-left
{
  border-radius: 4px 0px 0px 4px;

}
.br-right
{
  border-radius: 0px 4px 4px 0px;

}

.input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
 
/* Focus */
.input-file input[type=file]:focus + .input-file-btn {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
 
/* Hover/active */
.input-file-btn:hover {
	background-color: #59be6e;
}
.input-file:active .input-file-btn {
	background-color: #2E703A;
}
 
/* Disabled */
.input-file input[type=file]:disabled + .input-file-btn {
	background-color: #eee;
}
 li
 {
  margin: 10px;
 }
 input
 {
  width: 400px;
  border-radius: 2px;
 }

.footer{
  text-align: center;
  background: #222;
  height: 150px;
  padding: 50px;
  
}
.footer p{
  border-bottom: 1px solid #666;
    color: #eee;
    text-align:center;
    font-size: 20px;
    text-decoration: none;
    background: #222

}